<template>
  <div>
    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="name" slot-scope="name,record">
        <router-link :to="{name:'cameras',params:{id: record.id}}">{{ name }}</router-link>
      </template>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="deleteEquipment(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增模态框 -->
    <new
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findEquipments, deleteEquipment } from '@/api/equipment'

export default {
  name: 'EquipmentList',
  components: {
    Pagination,
    New: () => import('@/views/equipments/New'),
    Edit: () => import('@/views/equipments/Edit')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingRecord: {}, // 正在编辑的记录id
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '设备名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '设备序列号',
          dataIndex: 'no'
        },
        {
          title: '操作',
          width: 220,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    fetchData() {
      this.loading = true
      findEquipments(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    deleteEquipment(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteEquipment(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
