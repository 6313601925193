import request from '@/utils/request'

// 列出所有设备
export function findEquipments(params) {
  return request({
    url: `/equipments`,
    method: 'get',
    params: params
  })
}

// 更新设备
export function updateEquipment(id, data) {
  return request({
    url: `/equipments/${id}`,
    method: 'put',
    data
  })
}

// 创建设备
export function createEquipment(data) {
  return request({
    url: `/equipments`,
    method: 'post',
    data
  })
}

// 删除设备
export function deleteEquipment(id) {
  return request({
    url: `equipments/${id}`,
    method: 'delete'
  })
}
